import React from 'react';

const FirstPrizeIcon = (props) => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="55" height="55" rx="27.5" fill="#043B3B" />
      <path
        d="M17.9482 11.542L17.9258 14.9874L14.0871 16.6929H14.0829L14.1176 11.5178L17.9482 11.542Z"
        fill="#E2BCC0"
      />
      <path
        d="M46.0062 36.9376C46.0062 37.1963 45.8719 37.4367 45.6558 37.5761C42.4284 39.6413 38.8626 41.1953 35.1237 42.0743C32.6542 42.6542 30.0644 42.9412 27.4624 42.9371C24.8605 42.9068 22.2728 42.5835 19.8134 41.9733C16.0867 41.0457 12.5413 39.4453 9.34237 37.3377C9.12839 37.1963 8.99801 36.9538 9.00002 36.6951C9.00414 36.0788 9.70505 35.7191 10.2185 36.0727L10.7136 36.4162C15.7118 39.8677 21.5494 41.5691 27.4706 41.6156C33.3918 41.6479 39.2518 40.0232 44.2968 36.6365L44.7961 36.299C45.3137 35.9515 46.0106 36.3213 46.0062 36.9376Z"
        fill="#E2BCC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.6002 18.8877L43.4902 35.5487C36.703 39.9781 30.0076 40.2651 27.477 40.2489C19.3552 40.1923 13.6255 36.743 11.5289 35.3365L11.643 18.6756L27.6705 11.5L43.6002 18.8877ZM26.1513 28.1618L26.1472 28.6488C26.1472 29.0267 25.8395 29.3318 25.4504 29.3318C25.0612 29.3318 24.7617 29.0227 24.7617 28.6407L24.7657 28.2225C24.7698 27.7537 24.7087 27.3515 24.5905 27.0302C24.4581 26.7049 24.293 26.4684 24.0689 26.2826C23.8285 26.0785 23.5554 25.9431 23.2355 25.8562C22.881 25.7693 22.4898 25.7248 22.068 25.7248H22.015C21.6381 25.7248 21.2693 25.7632 20.9147 25.8299C20.5867 25.9006 20.3055 26.034 20.0732 26.2179C19.8328 26.4139 19.6514 26.6786 19.5251 27.0019C19.3845 27.3333 19.3152 27.7638 19.3112 28.3154V28.6023C19.3071 28.9802 18.9994 29.2854 18.6143 29.2854C18.2292 29.2854 17.9256 28.9762 17.9256 28.5943L17.9297 28.2204C17.9378 27.0201 18.2903 26.1007 18.979 25.4844C19.6596 24.8761 20.6947 24.5669 22.0558 24.5669H22.07L22.1087 24.5629H22.121C23.3883 24.571 24.3847 24.8721 25.0836 25.4641C25.7947 26.0603 26.1533 26.9655 26.1452 28.1578L26.1513 28.1618ZM37.3691 28.7253L37.3732 28.2383L37.3712 28.2343C37.3793 27.042 37.0207 26.1327 36.3096 25.5407C35.6128 24.9486 34.6163 24.6434 33.349 24.6354H33.3307L33.3042 24.6434H33.2614C31.9146 24.6434 30.8836 24.9526 30.203 25.5608C29.5184 26.1731 29.1659 27.0926 29.1577 28.2969L29.1536 28.6708C29.1536 29.0486 29.4613 29.3578 29.8423 29.3618C30.2234 29.3618 30.5351 29.0567 30.5392 28.6788V28.3919C30.5433 27.8403 30.6125 27.4098 30.7491 27.0744C30.8815 26.743 31.0649 26.4823 31.2972 26.2944C31.5254 26.1105 31.8106 25.9811 32.1407 25.9064C32.5034 25.8357 32.8722 25.7973 33.237 25.7973H33.2899C33.7158 25.8013 34.111 25.8458 34.4574 25.9327C34.7773 26.0115 35.0503 26.1509 35.2949 26.355C35.519 26.549 35.6902 26.7935 35.8124 27.1067C35.9347 27.43 35.9958 27.8301 35.9917 28.2949L35.9876 28.7172C35.9876 29.0951 36.2953 29.4043 36.6723 29.4083C37.0574 29.4083 37.3691 29.1032 37.3691 28.7253Z"
        fill="#E2BCC0"
      />
    </svg>
  );
};

export default FirstPrizeIcon;
