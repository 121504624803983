import React from "react";
import HtmlReactParser from "html-react-parser";
import { 
  FloorplanTabpanel, 
  GalleryItem, 
  GalleryTabpanel, 
  HeroContainer, 
  HeroInfoItem, 
  HeroTablist, 
  HeroTabpanel, 
  HeroTabpanels, 
  InfoTabpanel, 
  TitleText, 
  TourTabpanel, 
  VideoBlockHeading,
} from "raffle-house-uikit";
import { heroGalleryImages, heroInfoData } from "../../../lib/static-data";
import './styles.css';

const withUrl = (imageName) => `${process.env.REACT_APP_AWS_S3_URL}gallery/home/${imageName}.jpg`;

export default function Hero() {
  return (
    <HeroContainer>
      <HeroTablist />
      <HeroTabpanels>
        <HeroTabpanel id='tour'>
          <TourTabpanel
            video={process.env.REACT_APP_AWS_S3_URL + "videos/competition-video-28-06-2024.mp4"}
            tabletVideo={process.env.REACT_APP_AWS_S3_URL + "videos/competition-video-28-06-2024.mp4"}
            mobileVideo={process.env.REACT_APP_AWS_S3_URL + "videos/mobile-competition-video-28-06-2024.mp4"}
            poster={process.env.REACT_APP_AWS_S3_URL + "videos/preview-competition-video-28-06-2024.jpg"}
            tabletPoster={process.env.REACT_APP_AWS_S3_URL + "videos/preview-competition-video-28-06-2024.jpg"}
            mobilePoster={process.env.REACT_APP_AWS_S3_URL + "videos/preview-mobile-competition-video-28-06-2024.jpg"}
          >
            <VideoBlockHeading>
              <TitleText isCentered variant={"primary"} color='color-1'>
                Win this £2.7 million Kent Dream Home
              </TitleText>
            </VideoBlockHeading>
          </TourTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id='gallery'>
          <GalleryTabpanel>
            {heroGalleryImages.map((imageName, i) => (
              <GalleryItem key={i}>
                <img
                  srcSet={`
                      ${withUrl(`${imageName}_800x600`)} 800w,
                      ${withUrl(`${imageName}_1600x900`)} 1600w,
                      ${withUrl(`${imageName}_3840x2160`)} 3840w,
                    `}
                  sizes={`(max-width: 800px) 800px, (max-width: 1600px) 1600px, 3840px`}
                  src={`${withUrl(`${imageName}_1600x900`)}`}
                  alt='property'
                />
              </GalleryItem>
            ))}
          </GalleryTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id='floorplan'>
          <FloorplanTabpanel>
            <img
              className='hero-block-floorplan__img'
              srcSet={`${process.env.REACT_APP_AWS_S3_URL}floorplan/floorplan_mobile.png 1024w, ${process.env.REACT_APP_AWS_S3_URL}floorplan/floorplan_desctop.png 1910w`}
              sizes='(min-width: 768px) 1910px, 100vw'
              width={1910}
              height={720}
              alt='floorplan'
            />
          </FloorplanTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id='info'>
          <InfoTabpanel>
            {heroInfoData.map(({ title, content }, index) => {
              return (
                <HeroInfoItem 
                  title={title} 
                  content={content} 
                  key={index}
                  renderSubtitle={(string) => HtmlReactParser(string)}
                />
              )
            })}
          </InfoTabpanel>
        </HeroTabpanel>
      </HeroTabpanels>
    </HeroContainer>
  );
}
