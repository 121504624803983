import React from 'react';
import Helmet from 'react-helmet';

const MetaTags = ({
  title = 'Discount Raffle House | Win Your Dream Home',
  description = '',
  keywords = [''],
  canonical = process.env.REACT_APP_BASE_URL,
  robots = 'follow, noindex',
}) => {
  return (
    <Helmet
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords.join(),
        },
        {
          name: 'robots',
          content: robots,
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: canonical,
        },
      ]}
      title={title}
    >
      {process.env.REACT_APP_BASE_URL_LANDING ===
        'https://pagediscount-staging.rafflehouse.com' && (
        <meta name='robots' content='noindex' />
      )}
    </Helmet>
  );
};

export default MetaTags;
