import React from 'react';
import CtaButtonLayout from '../components/redesign/CtaButtonLayout/CtaButtonLayout';
import Header from '../components/redesign/Header/Header';
import Footer from '../components/redesign/Footer/Footer';
import PageLoader from '../components/redesign/PageLoader/PageLoader';
import Main from '../components/redesign/Main/Main';
import usePrize from '../hooks/usePrize';
import { doublePrizeData, singlePrizeData } from '../lib/static-data';
import MetaTags from '../components/meta-tags';
import useWidget from '../hooks/useWidget';
import useKlaviyo from '../hooks/useKlaviyo';
import useStackAdaptPixel from '../hooks/useStackAdaptPixel';
import { useTwitterHeadScript } from '../hooks/useTwitterHeadScript';
import { useLocation } from 'react-router-dom';
import tracking from '../helpers/tracking';

function App() {
  // useKlaviyo();
  useTwitterHeadScript();
  const location = useLocation();
  useStackAdaptPixel();
  const { raffle, prizeId, prizeLoading, raffles, subscriptionModels, isExpired, activeRaffles, isDoublePrize } =
    usePrize();
  const { widget: timelineWidget, isWidgetLoading: isTimelineWidgetLoading } = useWidget({ type: 'timeline-logo-rotation-text' });
  const { widget: footerWidget, isWidgetLoading: isFooterWidgetLoading } = useWidget({ type: 'footer-logo-rotation-text' });

  React.useEffect(() => {
    tracking.twitterPageViewTrack(location.pathname);
  }, [location]);


  const onCTAClick = () => {
    if (process.env.REACT_APP_BASE_URL) {
      window.open(process.env.REACT_APP_BASE_URL + '/ticket-selector');
    }
  };
  const onLearnMoreClick = () => {
    if (process.env.REACT_APP_BASE_URL) {
      window.open(process.env.REACT_APP_BASE_URL + '/how-it-works');
    }
  };

  const isLoading = prizeLoading
    || isTimelineWidgetLoading
    || isFooterWidgetLoading;

  return (
    <>
      <MetaTags />
      {isLoading ? (
        <CtaButtonLayout isDark>
          <Header raffle={raffle} />
          <PageLoader loading />
          <Footer insideComponent={true} widget={footerWidget}/>
        </CtaButtonLayout>
      ) : (
        <Main
          prizeId={prizeId}
          raffle={raffle}
          raffles={raffles}
          subscriptionModels={subscriptionModels}
          activeRaffles={activeRaffles}
          isExpired={isExpired}
          data={isDoublePrize ? doublePrizeData : singlePrizeData}
          onCTAClick={onCTAClick}
          onLearnMoreClick={onLearnMoreClick}
          widgets={{ timelineWidget, footerWidget }}
        />
      )}
    </>
  );
}

export default App;
