import React from 'react';

const SecondBannerWithText = (props) => {
  return (
    <svg className="banner" width="82" height="61" viewBox="0 0 82 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.2355 1.0917L15.4725 14.1579C12.4961 14.9554 9.21471 17.8014 8.00219 20.636L0.680056 37.7554C-0.636768 40.8342 0.430891 44.8188 3.11071 46.8267L18.0116 57.9915C20.4794 59.8405 24.7439 60.664 27.7203 59.8666L76.4831 46.8006C78.1032 46.3665 79.4607 45.3243 80.305 43.8666L80.3097 43.8584C81.1524 42.3989 81.3773 40.702 80.9427 39.0794L71.9582 5.54904C71.5237 3.92816 70.4814 2.5708 69.023 1.72711C67.5615 0.881802 65.8616 0.656121 64.2355 1.0917ZM68.9108 6.3656L77.8953 39.896C78.3452 41.5733 77.3451 43.3038 75.6665 43.7531L26.9037 56.8193C24.8294 57.3752 21.6237 56.7557 19.9033 55.4669L5.00241 44.3021C3.48712 43.1669 2.83605 40.7371 3.58072 38.9963L10.9029 21.8769C11.7344 19.9345 14.2506 17.7517 16.2892 17.2053L65.0522 4.13934C65.8632 3.92182 66.7122 4.03506 67.4433 4.458C68.1731 4.88001 68.6942 5.55751 68.9108 6.3656Z"
        fill="#043B3B"
      />
      <path
        d="M10.2979 34.266C7.90599 38.4088 9.33049 43.7251 13.4733 46.117C17.6161 48.5089 22.9325 47.0844 25.3243 42.9415C27.7162 38.7987 26.2917 33.4824 22.1489 31.0905C18.006 28.6987 12.6897 30.1232 10.2979 34.266ZM22.5921 41.3641C21.0701 44.0004 17.687 44.907 15.0505 43.3849C12.4142 41.8628 11.5076 38.4797 13.0298 35.8433C14.5519 33.2069 17.9349 32.3004 20.5714 33.8225C23.2078 35.3447 24.1142 38.7278 22.5921 41.3641Z"
        fill="#043B3B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.9108 6.3656L77.8953 39.896C78.3452 41.5733 77.3451 43.3038 75.6665 43.7531L26.9037 56.8193C24.8294 57.3752 21.6237 56.7557 19.9033 55.4669L5.00241 44.3021C3.48712 43.1669 2.83605 40.7371 3.58072 38.9963L10.9029 21.8769C11.7344 19.9345 14.2506 17.7517 16.2892 17.2053L65.0522 4.13934C65.8632 3.92182 66.7122 4.03506 67.4433 4.458C68.1731 4.88001 68.6942 5.55751 68.9108 6.3656ZM13.4733 46.117C9.33049 43.7251 7.90599 38.4088 10.2979 34.266C12.6897 30.1232 18.006 28.6987 22.1489 31.0905C26.2917 33.4824 27.7162 38.7987 25.3243 42.9415C22.9325 47.0844 17.6161 48.5089 13.4733 46.117Z"
        fill="#043B3B"
      />
      <path
        d="M31.3749 37.6469L30.9766 36.1602L32.1659 35.8415L31.3882 32.939L30.1989 33.2577L29.865 32.0117L31.0543 31.6931L30.6788 30.2914C30.4284 29.3569 30.5023 28.5378 30.9005 27.8342C31.3057 27.1187 32.0699 26.6104 33.1931 26.3094C33.7501 26.1602 34.3215 26.1032 34.9076 26.1384C35.4937 26.1735 36.0104 26.3083 36.4576 26.5425L36.1874 28.0262C35.7711 27.7938 35.3469 27.6646 34.9151 27.6387C34.4807 27.6034 34.0653 27.6388 33.6689 27.745C33.1214 27.8917 32.7522 28.1373 32.5612 28.4819C32.3678 28.817 32.338 29.2347 32.4721 29.735L32.8666 31.2075L35.217 30.5777L35.5508 31.8237L33.2005 32.4534L33.9782 35.3559L38.2966 34.1988L38.695 35.6855L31.3749 37.6469Z"
        fill="white"
      />
      <path
        d="M44.4662 34.1391L43.9578 32.2418L39.6111 33.4065L39.2545 32.0756L42.103 24.0738L43.6038 23.6716L45.368 30.2554L46.7697 29.8798L47.1718 31.3807L45.7701 31.7562L46.2785 33.6535L44.4662 34.1391ZM43.5557 30.741L42.5351 26.9324L40.9505 31.4391L43.5557 30.741Z"
        fill="white"
      />
      <path
        d="M49.4162 32.8128L46.7415 22.8309L48.2565 22.425L53.5451 28.0037L55.3217 20.5319L56.8083 20.1335L59.483 30.1154L57.8406 30.5554L56.0385 23.8301L54.5746 29.8068L53.5127 30.0914L49.2641 25.6756L51.0586 32.3727L49.4162 32.8128Z"
        fill="white"
      />
      <path
        d="M20.6222 48.1598C20.3408 48.2352 20.0771 48.2672 19.8313 48.2559C19.5879 48.2401 19.3646 48.1823 19.1612 48.0822L19.2144 47.7866C19.4306 47.8795 19.6406 47.9354 19.8443 47.9544C20.0506 47.969 20.2841 47.9414 20.545 47.8715C20.8711 47.7841 21.0978 47.6553 21.225 47.4851C21.3558 47.3139 21.3935 47.1254 21.3384 46.9195C21.2924 46.7478 21.1964 46.6301 21.0506 46.5662C20.9081 46.5013 20.6939 46.4778 20.4081 46.4955L19.8591 46.5267C19.519 46.548 19.2476 46.503 19.0448 46.3917C18.8456 46.2796 18.7114 46.0948 18.6425 45.8374C18.5864 45.628 18.5904 45.4301 18.6545 45.2437C18.722 45.0564 18.8404 44.8941 19.0096 44.7567C19.1779 44.616 19.3873 44.5121 19.6379 44.4449C20.139 44.3106 20.5794 44.3545 20.9589 44.5765L20.9043 44.8671C20.7069 44.7618 20.5136 44.6995 20.3245 44.6803C20.1345 44.6576 19.9314 44.6753 19.7151 44.7332C19.4268 44.8105 19.2135 44.9412 19.0753 45.1254C18.9404 45.3087 18.9038 45.5153 18.9654 45.7453C19.0142 45.9272 19.1077 46.0567 19.2461 46.1336C19.3835 46.2072 19.5805 46.2353 19.8371 46.2181L20.3912 46.1855C20.7572 46.1647 21.0436 46.2038 21.2504 46.3029C21.4563 46.3986 21.5924 46.57 21.6586 46.8171C21.711 47.0127 21.7043 47.2003 21.6383 47.3799C21.5749 47.5551 21.4569 47.7118 21.2842 47.85C21.1106 47.9848 20.89 48.0881 20.6222 48.1598Z"
        fill="white"
      />
      <path
        d="M24.11 47.2253C23.6466 47.3494 23.2617 47.3201 22.9552 47.1374C22.6478 46.9512 22.4307 46.6213 22.3037 46.1476L21.7105 43.9337L22.0246 43.8495L22.6109 46.0377C22.8243 46.834 23.2982 47.1338 24.0328 46.9369C24.7673 46.7401 25.0279 46.2436 24.8145 45.4472L24.2282 43.2591L24.5423 43.1749L25.1355 45.3888C25.2624 45.8625 25.2393 46.2568 25.0662 46.5718C24.8921 46.8833 24.5734 47.1011 24.11 47.2253Z"
        fill="white"
      />
      <path
        d="M26.5375 46.5307L25.5649 42.9009L26.9345 42.5339C27.3223 42.43 27.6414 42.4402 27.8918 42.5644C28.1455 42.6877 28.3166 42.9141 28.4049 43.2436C28.4775 43.5148 28.4626 43.7542 28.3601 43.9619C28.2575 44.1697 28.0771 44.3339 27.8188 44.4546C27.9811 44.47 28.1474 44.5689 28.3177 44.7514L29.3023 45.7898L28.9574 45.8823L28.0175 44.887C27.885 44.7496 27.7565 44.6681 27.6319 44.6427C27.5074 44.6172 27.3524 44.6292 27.1671 44.6789L26.4308 44.8762L26.8516 46.4465L26.5375 46.5307ZM26.3591 44.6085L27.3734 44.3367C27.9843 44.173 28.2218 43.8371 28.0856 43.3291C27.9504 42.8246 27.5774 42.6541 26.9664 42.8179L25.9521 43.0896L26.3591 44.6085Z"
        fill="white"
      />
      <path
        d="M30.0444 45.591L29.0718 41.9612L30.4413 41.5943C30.8292 41.4903 31.1483 41.5005 31.3986 41.6247C31.6524 41.748 31.8234 41.9744 31.9117 42.3039C31.9844 42.5751 31.9695 42.8145 31.8669 43.0223C31.7644 43.23 31.584 43.3942 31.3257 43.5149C31.488 43.5303 31.6543 43.6292 31.8246 43.8117L32.8092 44.8502L32.4643 44.9426L31.5243 43.9473C31.3919 43.8099 31.2634 43.7285 31.1388 43.703C31.0143 43.6775 30.8593 43.6896 30.674 43.7392L29.9377 43.9365L30.3585 45.5068L30.0444 45.591ZM29.866 43.6688L30.8802 43.397C31.4912 43.2333 31.7286 42.8975 31.5925 42.3895C31.4573 41.8849 31.0842 41.7145 30.4733 41.8782L29.459 42.15L29.866 43.6688Z"
        fill="white"
      />
      <path
        d="M33.5513 44.6513L32.5787 41.0216L34.8132 40.4228L34.8863 40.6957L32.9607 41.2117L33.3305 42.5915L35.1479 42.1045L35.221 42.3774L33.4036 42.8644L33.7871 44.2957L35.7127 43.7797L35.7858 44.0526L33.5513 44.6513Z"
        fill="white"
      />
      <path
        d="M37.4558 43.6051L36.9895 41.8649L35.0982 40.3465L35.4689 40.2471L37.0798 41.5427L37.8321 39.6139L38.1874 39.5187L37.3101 41.7845L37.775 43.5196L37.4558 43.6051Z"
        fill="white"
      />
      <path
        d="M41.0954 42.6299L40.1228 39.0001L41.2915 38.687C41.8682 38.5325 42.3541 38.5678 42.7492 38.793C43.1477 39.0173 43.4261 39.4247 43.5843 40.0151C43.7425 40.6054 43.7056 41.0991 43.4735 41.4961C43.2439 41.8887 42.8408 42.1622 42.2641 42.3167L41.0954 42.6299ZM41.335 42.2677L42.1639 42.0456C43.1593 41.7789 43.5195 41.1324 43.2445 40.1061C42.9704 39.0832 42.3357 38.7052 41.3403 38.9719L40.5114 39.194L41.335 42.2677Z"
        fill="white"
      />
      <path
        d="M44.9743 41.5905L44.0018 37.9608L45.3713 37.5938C45.7591 37.4899 46.0782 37.5 46.3286 37.6243C46.5824 37.7476 46.7534 37.974 46.8417 38.3035C46.9143 38.5746 46.8994 38.8141 46.7969 39.0218C46.6943 39.2295 46.5139 39.3938 46.2556 39.5145C46.418 39.5299 46.5843 39.6288 46.7545 39.8112L47.7391 40.8497L47.3942 40.9421L46.4543 39.9469C46.3218 39.8095 46.1933 39.728 46.0688 39.7025C45.9442 39.677 45.7892 39.6891 45.6039 39.7388L44.8676 39.9361L45.2884 41.5064L44.9743 41.5905ZM44.7959 39.6683L45.8102 39.3966C46.4212 39.2329 46.6586 38.897 46.5225 38.389C46.3873 37.8844 46.0142 37.714 45.4032 37.8777L44.3889 38.1495L44.7959 39.6683Z"
        fill="white"
      />
      <path
        d="M48.4812 40.6509L47.5086 37.0211L49.7431 36.4224L49.8162 36.6953L47.8907 37.2112L48.2604 38.591L50.0779 38.104L50.151 38.3769L48.3335 38.8639L48.717 40.2952L50.6426 39.7793L50.7157 40.0521L48.4812 40.6509Z"
        fill="white"
      />
      <path
        d="M51.1406 39.9383L51.7486 35.885L52.0318 35.8091L54.5799 39.0167L54.2504 39.105L53.574 38.2488L51.6278 38.7703L51.4753 39.8486L51.1406 39.9383ZM51.9873 36.2404L51.6731 38.4657L53.3824 38.0077L51.9976 36.2377L51.9873 36.2404Z"
        fill="white"
      />
      <path
        d="M55.3042 38.8227L54.3316 35.1929L54.5993 35.1212L56.7735 37.4688L57.4722 34.3514L57.74 34.2796L58.7125 37.9094L58.4191 37.988L57.6107 34.971L56.9764 37.7731L56.7499 37.8338L54.7854 35.7335L55.5925 38.7454L55.3042 38.8227Z"
        fill="white"
      />
      <path
        d="M61.1613 37.2533L60.1887 33.6235L60.5027 33.5393L60.9442 35.1869L63.2611 34.5661L62.8196 32.9185L63.1337 32.8344L64.1063 36.4642L63.7922 36.5483L63.337 34.8493L61.0201 35.4701L61.4753 37.1691L61.1613 37.2533Z"
        fill="white"
      />
      <path
        d="M66.6445 35.8282C66.3116 35.9174 66.003 35.9191 65.7189 35.8334C65.4381 35.7468 65.1936 35.5824 64.9852 35.3402C64.7803 35.0971 64.6268 34.7851 64.5247 34.4041C64.4226 34.0231 64.3995 33.6761 64.4555 33.3631C64.5114 33.0502 64.6415 32.7872 64.8456 32.5743C65.0488 32.358 65.3169 32.2052 65.6499 32.116C65.9863 32.0259 66.2948 32.0242 66.5755 32.1108C66.8588 32.1931 67.1029 32.3558 67.3078 32.5989C67.5152 32.8376 67.67 33.1474 67.7721 33.5284C67.8751 33.9129 67.8969 34.262 67.8376 34.5759C67.7816 34.8889 67.652 35.1535 67.4488 35.3699C67.2456 35.5862 66.9775 35.739 66.6445 35.8282ZM66.5673 35.5398C66.9757 35.4304 67.2565 35.208 67.4094 34.8727C67.5624 34.5374 67.5722 34.121 67.4388 33.6233C67.3055 33.1256 67.0892 32.7715 66.79 32.561C66.4933 32.3462 66.139 32.294 65.7271 32.4044C65.3187 32.5138 65.038 32.7362 64.885 33.0715C64.7355 33.4058 64.7269 33.8202 64.8594 34.3144C64.9918 34.8087 65.2068 35.1649 65.5044 35.3832C65.8045 35.5971 66.1588 35.6493 66.5673 35.5398Z"
        fill="white"
      />
      <path
        d="M69.1758 35.1058L68.2032 31.476L68.471 31.4043L70.6451 33.7519L71.3439 30.6345L71.6116 30.5627L72.5842 34.1925L72.2907 34.2711L71.4823 31.2541L70.8481 34.0562L70.6215 34.1169L68.6571 32.0166L69.4642 35.0285L69.1758 35.1058Z"
        fill="white"
      />
      <path
        d="M73.6378 33.9102L72.6652 30.2804L74.8997 29.6817L74.9728 29.9546L73.0472 30.4705L73.4169 31.8504L75.2344 31.3634L75.3075 31.6362L73.4901 32.1232L73.8736 33.5545L75.7992 33.0386L75.8723 33.3115L73.6378 33.9102Z"
        fill="white"
      />
    </svg>
  );
};
const DreamHomeBanner = (props) => {
  return (
    <div className="banner">
      <svg width="82" height="61" viewBox="0 0 82 61" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.2916 0.216841L15.5253 13.284C12.5487 14.0814 9.26708 16.9276 8.05447 19.7624L0.73185 36.883C-0.585061 39.962 0.482672 43.9468 3.16267 45.9549L18.0645 57.1204C20.5325 58.9695 24.7973 59.7932 27.7739 58.9957L76.54 45.9287C78.1602 45.4946 79.5178 44.4524 80.3621 42.9946L80.3668 42.9864C81.2096 41.5268 81.4345 39.8298 80.9999 38.2071L72.0148 4.67448C71.5802 3.05349 70.5379 1.69604 69.0794 0.85229C67.6178 0.00692445 65.9177 -0.218772 64.2916 0.216841ZM68.9672 5.54292L77.9524 39.0755C78.4023 40.753 77.4021 42.4836 75.7234 42.9329L26.9573 56C24.8829 56.556 21.677 55.9364 19.9564 54.6475L5.05459 43.482C3.5392 42.3467 2.88809 39.9167 3.6328 38.1758L10.9554 21.0553C11.7871 19.1127 14.3034 16.9298 16.3422 16.3834L65.1084 3.31651C65.9194 3.09898 66.7686 3.21223 67.4997 3.63519C68.2295 4.05723 68.7507 4.73478 68.9672 5.54292Z" />
        <path d="M10.1772 33.4452C7.78521 37.5883 9.2098 42.905 13.3529 45.297C17.496 47.689 22.8127 46.2644 25.2047 42.1213C27.5967 37.9782 26.1721 32.6615 22.029 30.2695C17.8859 27.8775 12.5693 29.3021 10.1772 33.4452ZM22.4722 40.4919C20.95 43.1284 17.5668 44.0351 14.9301 42.5128C12.2937 40.9907 11.387 37.6074 12.9093 34.9707C14.4315 32.3341 17.8147 31.4276 20.4513 32.9499C23.0879 34.4721 23.9944 37.8554 22.4722 40.4919Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.9672 5.54292L77.9524 39.0755C78.4023 40.753 77.4021 42.4836 75.7234 42.9329L26.9573 56C24.8829 56.556 21.677 55.9364 19.9564 54.6475L5.05459 43.482C3.5392 42.3467 2.88809 39.9167 3.6328 38.1758L10.9554 21.0553C11.7871 19.1127 14.3034 16.9298 16.3422 16.3834L65.1084 3.31651C65.9194 3.09898 66.7686 3.21223 67.4997 3.63519C68.2295 4.05723 68.7507 4.73478 68.9672 5.54292ZM13.3529 45.297C9.2098 42.905 7.78521 37.5883 10.1772 33.4452C12.5693 29.3021 17.8859 27.8775 22.029 30.2695C26.1721 32.6615 27.5967 37.9782 25.2047 42.1213C22.8127 46.2644 17.496 47.689 13.3529 45.297Z"
        />
      </svg>
      <div className="banner-text-box">
        <p>{props.title ? props.title : ''}</p>
        <p>{props.subtitle ? props.subtitle : ''}</p>
      </div>
    </div>
  );
};

export default DreamHomeBanner;
