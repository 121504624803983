import { useEffect } from 'react';

function useKlaviyo() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.REACT_APP_KLAVIYO_PUBLIC_API_KEY}`;
    script.async = true;
    script.onload = function () {
      console.log('Klaviyo connected!');
    };

    document.head.appendChild(script);
  }, []);
}

export default useKlaviyo;
