import React from 'react';

const SecondPrizeIcon = (props) => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="55" height="55" rx="27.5" fill="#E2BCC0" />
      <path
        d="M18.4482 11.542L18.4258 14.9874L14.5871 16.6929H14.5829L14.6176 11.5178L18.4482 11.542Z"
        fill="#043B3B"
      />
      <path
        d="M46.5062 36.9376C46.5062 37.1963 46.3719 37.4367 46.1558 37.5761C42.9284 39.6413 39.3626 41.1953 35.6237 42.0743C33.1542 42.6542 30.5644 42.9412 27.9624 42.9371C25.3605 42.9068 22.7728 42.5835 20.3134 41.9733C16.5867 41.0457 13.0413 39.4453 9.84237 37.3377C9.62839 37.1963 9.49801 36.9538 9.50002 36.6951C9.50414 36.0788 10.2051 35.7191 10.7185 36.0727L11.2136 36.4162C16.2118 39.8677 22.0494 41.5691 27.9706 41.6156C33.8918 41.6479 39.7518 40.0232 44.7968 36.6365L45.2961 36.299C45.8137 35.9515 46.5106 36.3213 46.5062 36.9376Z"
        fill="#043B3B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.1002 18.8877L43.9902 35.5487C37.203 39.9781 30.5076 40.2651 27.977 40.2489C19.8552 40.1923 14.1255 36.743 12.0289 35.3365L12.143 18.6756L28.1705 11.5L44.1002 18.8877ZM26.6513 28.1618L26.6472 28.6488C26.6472 29.0267 26.3395 29.3318 25.9504 29.3318C25.5612 29.3318 25.2617 29.0227 25.2617 28.6407L25.2657 28.2225C25.2698 27.7537 25.2087 27.3515 25.0905 27.0302C24.9581 26.7049 24.793 26.4684 24.5689 26.2826C24.3285 26.0785 24.0554 25.9431 23.7355 25.8562C23.381 25.7693 22.9898 25.7248 22.568 25.7248H22.515C22.1381 25.7248 21.7693 25.7632 21.4147 25.8299C21.0867 25.9006 20.8055 26.034 20.5732 26.2179C20.3328 26.4139 20.1514 26.6786 20.0251 27.0019C19.8845 27.3333 19.8152 27.7638 19.8112 28.3154V28.6023C19.8071 28.9802 19.4994 29.2854 19.1143 29.2854C18.7292 29.2854 18.4256 28.9762 18.4256 28.5943L18.4297 28.2204C18.4378 27.0201 18.7903 26.1007 19.479 25.4844C20.1596 24.8761 21.1947 24.5669 22.5558 24.5669H22.57L22.6087 24.5629H22.621C23.8883 24.571 24.8847 24.8721 25.5836 25.4641C26.2947 26.0603 26.6533 26.9655 26.6452 28.1578L26.6513 28.1618ZM37.8691 28.7253L37.8732 28.2383L37.8712 28.2343C37.8793 27.042 37.5207 26.1327 36.8096 25.5407C36.1128 24.9486 35.1163 24.6434 33.849 24.6354H33.8307L33.8042 24.6434H33.7614C32.4146 24.6434 31.3836 24.9526 30.703 25.5608C30.0184 26.1731 29.6659 27.0926 29.6577 28.2969L29.6536 28.6708C29.6536 29.0486 29.9613 29.3578 30.3423 29.3618C30.7234 29.3618 31.0351 29.0567 31.0392 28.6788V28.3919C31.0433 27.8403 31.1125 27.4098 31.2491 27.0744C31.3815 26.743 31.5649 26.4823 31.7972 26.2944C32.0254 26.1105 32.3106 25.9811 32.6407 25.9064C33.0034 25.8357 33.3722 25.7973 33.737 25.7973H33.7899C34.2158 25.8013 34.611 25.8458 34.9574 25.9327C35.2773 26.0115 35.5503 26.1509 35.7949 26.355C36.019 26.549 36.1902 26.7935 36.3124 27.1067C36.4347 27.43 36.4958 27.8301 36.4917 28.2949L36.4876 28.7172C36.4876 29.0951 36.7953 29.4043 37.1723 29.4083C37.5574 29.4083 37.8691 29.1032 37.8691 28.7253Z"
        fill="#043B3B"
      />
    </svg>
  );
};

export default SecondPrizeIcon;
