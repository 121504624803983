import React from 'react';
import './styles.css';

const Loading = (props) => {
  return (
    <div className="loading">
      <div className="spinner"></div> <p className="text">{props.text ? props.text : ''}</p>
    </div>
  );
};

export default Loading;
