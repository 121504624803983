import React, { useState } from 'react';
import { tr } from '../../../helpers/languages';
import sloseIcon from '../../../assets/images/redesign/close-icon.svg';
import './styles.css';

export default function CookieBar() {
  const [cookies, setCookies] = useState(true);
  const acceptCookies = () => setCookies(true);
  let content = '';
  if (!cookies) {
    content = (
      <React.Fragment>
        <div className='cookie-wrapper-new'>
          <div className='cookie-container-new'>
            {/* <div className='cookie-text'>{tr('COOKIES_MESSAGE')}</div> */}
            <div className='cookie-text'>We use cookies to improve your experience on our website. Cookies allow us to understand how users interact with our website so we can improve our services.</div>
            <div className='cookie-text'>The cookies we use do not store personal information. We use analytical and functional cookies to analyse website usage and remember your preferences.</div>
            <div className='cookie-text'>By clicking "Got It", you agree to the use of cookies on our website. You can adjust your browser settings to change your cookie preferences.</div>
            <div>
              <button onClick={acceptCookies} className='rafflebtn primary'>
                {tr('GOT_IT')}
              </button>

              <button onClick={acceptCookies} className='cookie-close-button'>
                <img src={sloseIcon} width={24} height={24} alt='close-button' />
              </button>

              <button className='rafflebtn primary readmore'>
                <a href={process.env.REACT_APP_BASE_URL + '/legal/1'}>{tr('READ_MORE')}</a>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return <div>{content}</div>;
}
