import React from 'react';
import {TitleText} from "raffle-house-uikit";
import PartnersSlider from '../PartnersSlider/PartnersSlider';
import './styles.css';
import image1 from '../../../assets/images/redesign/charities/btr_logo.png';
// import image2  from "../../../assets/images/redesign/charities/britishHeart.png";
// import image3  from "../../../assets/images/redesign/charities/barnardos.jpg";
// import image4  from "../../../assets/images/redesign/charities/british-red-bross.jpg";
// import image5  from "../../../assets/images/redesign/charities/children-in-need.jpg";
import image6 from '../../../assets/images/redesign/charities/war_paws.png';
// import image7  from "../../../assets/images/redesign/charities/duke-of-edinburgh-awards.jpg";
// import image8  from "../../../assets/images/redesign/charities/the-flying-seagull-project.png";
// import image9  from "../../../assets/images/redesign/charities/keep-britain-tidy.jpg";
// import image10 from "../../../assets/images/redesign/charities/national-trust.jpg";
// import image11 from "../../../assets/images/redesign/charities/nspcc.jpg";
// import image12 from "../../../assets/images/redesign/charities/parkinsons-uk.jpg";
// import image13 from "../../../assets/images/redesign/charities/prostate-cancer-uk.jpg";
// import image14 from "../../../assets/images/redesign/charities/save-the-children.jpg";
// import image15 from "../../../assets/images/redesign/charities/st-john-ambulance.jpg";
import image16 from '../../../assets/images/redesign/charities/pipal-tree-logo.png';
import image17 from '../../../assets/images/redesign/charities/whenyouwishuponastar.png';
import image18 from '../../../assets/images/redesign/charities/sense.png';

const CharityBlock = (props) => {
  const charityImages = [
    { src: image1 },
    // { src: image2  },
    // { src: image3  },
    // { src: image4  },
    // { src: image5  },
    { src: image6 },
    // { src: image7  },
    // { src: image8  },
    // { src: image11 },
    // { src: image12 },
    // { src: image13 },
    // { src: image14 },
    { src: image16 },
    { src: image17 },
    { src: image18 },
  ];
  return (
    <div className='charity-block'>
      <TitleText isCentered as='h2' variant={'primary'} color='color-5'>
        Dream big. <br className='mobile-spacer' /> Do good.
      </TitleText>
      <div className='charities-list'>
        <div className='left-side'>
          <p className='title'>
            Your donation.
            <br /> <span>Your choice.</span>
          </p>
          <p className='sub'>
            Win a home and donate to one of our charity partners.
          </p>
        </div>
        <div className='right-side'>
          <PartnersSlider
            isDark
            isTicker
            images={charityImages}
            isCharitySlider
          />
        </div>
      </div>
    </div>
  );
};

export default CharityBlock;
