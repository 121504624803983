import { useEffect, useState } from 'react';
import ticketsService from '../services/tickets';

const usePrize = () => {
  const [isDoublePrize, setIsDoublePrize] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const [raffle, setRaffle] = useState(null);
  const [activeRaffles, setActiveRaffles] = useState([]);
  const [raffles, setRaffles] = useState([]);
  const [subscriptionModels, setSubscriptionModels] = useState([]);
  const [prizeId, setPrizeId] = useState(null);
  const [prizeLoading, setPrizeLoading] = useState(true);

  useEffect(() => {
    setPrizeLoading(true);
    ticketsService
      .getActiveRafflesAndSubscriptionModels()
      .then((response) => {
        let { raffles, subscriptionModels } = response;

        if (raffles.length > 0) {
          // raffles.sort(function (a, b) {
          //   return new Date(a.endsAt) - new Date(b.endsAt);
          // });
          const activeRaffles = raffles.filter(
            (raffle) => new Date(raffle.endsAt) > new Date()
          );
          setActiveRaffles(activeRaffles);
          setRaffles(raffles);

          console.log('activeRaffles: ', activeRaffles);
          if (activeRaffles.length > 0) {
            if (activeRaffles.length > 1) {
              setIsDoublePrize(true);
            }
            console.log(' has active');
            setRaffle(activeRaffles[0]);
            setPrizeId(activeRaffles[0]._id);
            // activeId = activeRaffles[0]._id;
          } else {
            setIsDoublePrize(false);
            setRaffle(raffles[0]);
            setPrizeId(raffles[0]._id);
            setExpired(true);
          }
          if (subscriptionModels.length > 0) {
            setSubscriptionModels([subscriptionModels[0]]);
          }

          // setRaffles(raffles);
          // setPrizeId(raffles[0]._id);
          // setRaffle(raffles[0]);
        }
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      })
      .finally(() => {
        setPrizeLoading(false);
      });
  }, []);

  return {
    prizeId,
    prizeLoading,
    raffle,
    raffles,
    isExpired,
    activeRaffles,
    subscriptionModels,
    isDoublePrize,
  };
};

export default usePrize;
