//  Analytics tracking functions
const googleAnalytics4AddItemToBasket = (id, totalCost, ticketsQuantity) => {
  //   ReactGA4.event('AddItemToBasketFromDiscount', {
  //     category: 'ecommerce',
  //     debug_mode: true,
  //     action: 'click',
  //     id: id,
  //     currency: 'GBP',
  //     affiliation: 'Raffle House',
  //     quantity: ticketsQuantity,
  //     price: totalCost,
  //     value: totalCost,
  //   });
  if (
    // process.env.NODE_ENV === 'production' &&
    typeof window.gtag === 'function'
  ) {
    window.gtag('event', 'AddItemToBasketFromDiscount', {
      category: 'ecommerce',
      debug_mode: true,
      action: 'click',
      id: id,
      currency: 'GBP',
      affiliation: 'Raffle House',
      quantity: ticketsQuantity,
      price: totalCost,
      value: totalCost,
    });
  }
};

export { googleAnalytics4AddItemToBasket };
