import React from 'react';
import './style.css';

const PageLoader = (props) => {
  return (
    <div className='loader-wrapper'>
      <div className={`loader ${props.small ? 'small' : ''} ${props.reverseColor ? 'green-bg' : ''}`}>
        <div />
        <div />
        <div />
        <div />
        {props.loading ? (
          <React.Fragment>
            <span>{props.text ? props.text : ''}</span>
            <span>{props.loading}</span>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default PageLoader;
