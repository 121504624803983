import DoublePrizeTicketCard from '../DoublePrizeTicketCard/index';
import TicketCard from '../../TicketCard/TicketCard';

const NewPrizeCard = (props) => {
  const isDoublePrize = props.raffles.length > 1;
  if (isDoublePrize) {
    return <DoublePrizeTicketCard {...props} theme='light' />;
  }
  return <TicketCard {...props} />;
};

export default NewPrizeCard;
